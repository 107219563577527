import { createSelector } from '@reduxjs/toolkit';

import type { ReduxStateType } from 'Core/store.ts';

const productSelector = (state: ReduxStateType) => state.product;

export const extractedColorsSelector = createSelector(
  productSelector,
  ({ extractedColors }) => extractedColors,
);

export const productDataSelector = createSelector(productSelector, ({ data }) => data);

export const verifyFitmentProductDataSelector = createSelector(
  productSelector,
  ({ verifyFitmentData }) => verifyFitmentData,
);
