import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { isolatedVerifyFitment } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { setResponse } from 'Core/actions/response.ts';
import { fromPayload } from 'Core/reducers/common.ts';
import { setExtractedColors, setProductData } from 'Core/actions/product.ts';

import type { WeightedColorsArray } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

export default combineReducers({
  data: createReducer(null as ServerModel.SearchItem | null, (builder) =>
    builder.addCase(setProductData, fromPayload),
  ),
  extractedColors: createReducer(null as WeightedColorsArray | null, (builder) =>
    builder.addCase(setExtractedColors, fromPayload),
  ),
  verifyFitmentData: createReducer(null as ServerModel.SearchItem | null, (builder) =>
    builder.addCase(setResponse, (state, { payload, meta: { isolatedKey } }) =>
      isolatedKey === isolatedVerifyFitment ? payload.Items[0] ?? null : state,
    ),
  ),
});
