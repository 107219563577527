import _findKey from 'lodash-es/findKey.js';
import _forIn from 'lodash-es/forIn.js';

import { mainConfig } from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import _transform from 'lodash-es/transform.js';

import { DisplayMode } from 'Modules/serverApi/types';

import Step from 'Models/step.ts';

class FacetsConfig {
  _config;
  _defaultFacets;
  _parentFacets = {};

  constructor(config) {
    this._config = config;

    this._setDefaultFacets();
    this._setParentFacets();
    this._setSubFacetFields(this._parentFacets);
  }

  _setDefaultFacets() {
    this._defaultFacets = this._config.Widgets.reduce((map, { defaultFacetConfig, name }) => {
      if (name && defaultFacetConfig) {
        map[name] = defaultFacetConfig;
      }
      return map;
    }, {});
  }

  _setParentFacets() {
    _forIn(this.configs, (config) =>
      forEachTemplateIn(
        config,
        ({ childFields, fields, singleFacet }) =>
          childFields &&
          fields.forEach((field) => (this._parentFacets[field] = { childFields, singleFacet })),
      ),
    );
  }

  _setSubFacetFields(parentFacets) {
    this.subFacetFields = _transform(
      parentFacets,
      (subFacetFields, { childFields, singleFacet }) => {
        if (singleFacet) {
          subFacetFields.push(...childFields);
        }
      },
      [],
    );
  }

  get configs() {
    return this._config.facets || {};
  }

  get hideSelectedTreeValues() {
    return this._config.FacetsIsTreeSelectedHide;
  }

  getFacetConfig(displayMode, field, facetPanelName, caseNumber, isInFacetDialog) {
    const facetConfig = this._extractRawTemplate(field, facetPanelName);

    if (field === 'review') {
      return { ...facetConfig, name: 'reviewFacet', type: 'simpleFacet' };
    }

    const isPriceField = field.toLowerCase() === 'price';
    const isFacetNameOverridden = !!facetConfig.name && facetConfig.name !== 'simpleFacet';

    switch (displayMode) {
      case DisplayMode.Ranges:
        if (isPriceField) {
          return { ...facetConfig, name: 'priceFacet', type: 'rangedFacet' };
        }
        return { ...facetConfig, name: 'simpleFacet', type: 'simpleFacet' };
      case DisplayMode.Slider: {
        const facetName = isPriceField
          ? 'priceFacet'
          : !isFacetNameOverridden
            ? 'sliderFacet'
            : facetConfig.name;
        return { ...facetConfig, name: facetName, type: 'rangedFacet' };
      }
      case DisplayMode.AdvancedColor:
        return {
          ...facetConfig,
          name: isFacetNameOverridden ? facetConfig.name : 'advancedColorFacet',
          type: 'advancedColorFacet',
        };
      case DisplayMode.Select:
        return isInFacetDialog
          ? { ...facetConfig, name: 'simpleFacet', type: 'simpleFacet' }
          : { ...facetConfig, name: 'selectFacet', type: 'selectFacet' };
      case DisplayMode.Size:
        return {
          ...facetConfig,
          name: isFacetNameOverridden ? facetConfig.name : 'sizeFacet',
          type: 'sizeFacet',
        };
      case DisplayMode.Toggle:
        return {
          ...facetConfig,
          name: isFacetNameOverridden ? facetConfig.name : 'toggleFacet',
          type: 'toggleFacet',
        };
      case DisplayMode.Switch: {
        const switchCase = facetConfig.cases[caseNumber];
        return { ...facetConfig, name: switchCase.type, ...switchCase };
      }
      case DisplayMode.Default:
        return { ...facetConfig, type: 'simpleFacet' };
      default:
        return { ...facetConfig };
    }
  }

  getSwitcherArgs(field, facetPanelName) {
    const { type, cases, getInitCase = () => 0 } = this._extractRawTemplate(field, facetPanelName);
    const casesCount = type === 'switchFacet' ? cases.length : 1;
    return { casesCount, getInitCase };
  }

  getChildrenForField(field) {
    return this._parentFacets[field] || { childFields: [] };
  }

  useImperialForField(field) {
    const fieldWithStep = this.subFacetFields.includes(field)
      ? _findKey(this._parentFacets, ({ childFields }) => childFields.includes(field))
      : field;
    const { step } = this._extractRawTemplate(fieldWithStep);
    return step && new Step(step).imperial;
  }

  _extractRawTemplate(field, facetPanelName) {
    const defaultConfig = (facetPanelName && this._defaultFacets[facetPanelName]) || {};
    let { type, name } = this._findTemplateTypeName((t) => t.fields.includes(field));

    if (type) {
      const typeConfig = this.configs[type];
      const fieldConfig = Array.isArray(typeConfig)
        ? typeConfig.find((c) => c.fields.includes(field))
        : typeConfig;

      const config = name ? fieldConfig.templates[name] : fieldConfig;

      return { ...defaultConfig, type, name, ...config };
    }

    name = type = 'simpleFacet'; // eslint-disable-line no-multi-assign
    return { type, name, ...defaultConfig };
  }

  _findTemplateTypeName(check, checkCases) {
    let name = null;

    const type = _findKey(this.configs, (config) => {
      if (Array.isArray(config)) {
        for (let i = 0; i < config.length; i++) {
          name = findTemplateIn(config[i], check, checkCases);

          if (typeof name === 'string') {
            break;
          }
        }
      } else {
        name = findTemplateIn(config, check, checkCases);
      }

      return typeof name === 'string';
    });

    return type ? { type, name } : {};
  }
}

const facetsConfig = new FacetsConfig(mainConfig);
window.Convermax.templates.config.facetsConfig = facetsConfig;
export default facetsConfig;

function findTemplateIn(config, check, checkCases) {
  function checkWithCases(template) {
    return checkCases && template.type === 'switchFacet'
      ? template.cases.some((switchCase) => check({ ...template, ...switchCase }))
      : check(template);
  }

  if (config) {
    if (config.templates) {
      return _findKey(config.templates, checkWithCases);
    } else if (checkWithCases(config)) {
      return '';
    }
  }
}

function forEachTemplateIn(config, callback) {
  if (config) {
    if (config.templates) {
      _forIn(config.templates, callback);
    } else {
      callback(config);
    }
  }
}
