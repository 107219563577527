import { useSelector } from 'react-redux';

import { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import {
  createFitmentSearchRequestSelectionSelector,
  fitmentSearchBaseFieldsSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { FacetValueBase } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { equalBy } from 'Utils/array.js';
import { cloneSafe } from 'Utils/components.ts';
import type { InnerProps as Props } from './vehicleDropdowns.tsx';

const stub = () => {
  // stub
};

export default function StubDropdowns({
  template,
  name,
  aboutToChange,
  appendedClasses,
  collapsing,
  fields,
  isolatedKey,
  isVehicleSelected,
  openDialog,
  rootRef,
  useNativeDropdown,
  useSearchableDropdown,
}: Props) {
  const fitmentBaseFields = useSelector(fitmentSearchBaseFieldsSelector);
  const selectFields = !fields || equalBy(fitmentBaseFields, fields, (v) => v) ? fitmentBaseFields : [];
  const vehicle = useVehicle(isolatedKey, selectFields);

  const selects = createSelectRepeater(
    selectFields.map((field, i) => ({
      entries: [{ value: vehicle[field] || '', term: '', selected: field in vehicle }],
      extraClassName: 'cm_vehicle-widget_select',
      disabled: i !== 0,
      loading: i === 0,
      hideNullOption: true,
      title: field,
      key: field,
      index: i,
      useNativeDropdown: !useSearchableDropdown && useNativeDropdown,
      isSearchable: useSearchableDropdown,
      onChange: () => {
        /* stub */
      },
    })),
  );

  const component = template.call({
    name,
    aboutToChange,
    selects,
    vehicleString: '',
    vehicleValues: [],
    vehicle: {},
    allowToSetVehicle: false,
    allowToDiscardVehicle: false,
    selectVehicle: stub,
    discardVehicle: stub,
    discardExtraFields: stub,
    fitmentSearchTitle: fitmentSearchConfig.fitmentSearchTitle,
    template: 'active',
    isVehicleSelected,
    selection: {},
    ...collapsing,
    isVehicleSelectionForced: false,
    openDialog,
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}

function useVehicle(isolatedKey: IsolatedKey, fields: string[]) {
  return useSelector((state) => {
    const selection = createFitmentSearchRequestSelectionSelector(isolatedKey)(state) as FacetValueBase[];
    return Object.fromEntries(
      fields
        .map((field) => [field, selection.find((v) => v.field === field)?.value])
        .filter(([_, value]) => value) as [string, string][],
    );
  });
}
