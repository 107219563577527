import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import {
  createFacetSelector,
  searchRequestSelectionSelector,
  searchQuerySelector,
  totalHitsSelector,
} from 'Core/selectors/search.js';
import { createFitmentSearchResponseAllFacetsSelector } from 'Core/selectors/fitmentSearch/index.js';

import type { TemplateFunction } from 'Components/types.ts';
import type FacetData from 'Models/facet.ts';

type Params = {
  isToggled: boolean;
  toggleFacet: () => void;
};

type Props = {
  template: TemplateFunction<Params>;
  toggledFacet: { field: string; term: string };
  visibleIfFields: Array<string>;
  initToggled?: boolean;
};

const FacetToggle: FC<Props> = ({ template, toggledFacet, visibleIfFields, initToggled }) => {
  const dispatch = useDispatch();
  const [toggledByUser, setToggledByUser] = useState(false);

  const isVisible = useVisible(visibleIfFields, toggledFacet);

  const isToggled = useSelector(searchRequestSelectionSelector).some(
    (el: { field: string; term: string }) => el.field === toggledFacet.field && el.term === toggledFacet.term,
  );

  useEffect(
    function hideWhenFieldsAreNotSelected() {
      window.document.body.classList.toggle('cm_hide-facet-toggle', !isVisible);
    },
    [isVisible],
  );

  useEffect(() => {
    if (initToggled && isVisible && !toggledByUser && !isToggled) {
      dispatch(toggle(toggledFacet));
    }
  }, [dispatch, initToggled, toggledByUser, isToggled, isVisible, toggledFacet]);

  const toggleFacet = () => {
    setToggledByUser(true);
    dispatch(toggle(toggledFacet));
  };

  return template.call({
    isToggled,
    toggleFacet,
  });
};

export default FacetToggle;

function useVisible(visibleIfFields: Array<string>, toggledFacet: { field: string; term: string }): boolean {
  const facetsSelector = useMemo(() => createFitmentSearchResponseAllFacetsSelector(), []);
  const isAllFacetsHaveSelection = useSelector((state) => {
    const facets = facetsSelector(state);
    return visibleIfFields.every((field) => facets.get(field)?.selection.length);
  });

  const responseQuery = useSelector(searchQuerySelector);
  const toggledResponseFacet = useSelector(createFacetSelector(toggledFacet.field)) as FacetData;
  const fitmentResponseFacet = useSelector(createFacetSelector('Fitment')) as FacetData;
  const isUniversalFitSelected = fitmentResponseFacet?.selection.some((v) => v.term === 'Universal-Fit');
  const responseTotalHits = useSelector(totalHitsSelector);

  const isFullyToggledFacetSearchResults =
    toggledResponseFacet?.values.find((v) => v.term.toString() === toggledFacet.term)?.hitCount ===
      responseTotalHits && !isUniversalFitSelected;

  return !responseQuery && !isFullyToggledFacetSearchResults && isAllFacetsHaveSelection;
}
