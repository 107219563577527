import _uniq from 'lodash-es/uniq.js';
import _uniqBy from 'lodash-es/uniqBy.js';

import FacetValue from './facetValue.ts';
import WeightedColor from './weightedColor.ts';

import type { ArrayCallback, ArrayPredicate, ArrayReducer } from 'Core/types.ts';
import type { FacetValueBase } from './facetValue.ts';

export default class WeightedColorsArray {
  static toggleAndNormNotModified(
    colorValues: FacetValueBase[],
    value: FacetValueBase,
    wasSelected: boolean,
  ): FacetValueBase[] {
    return (wasSelected ? removeAndNormNotModified : addAndNormNotModified)(colorValues, value);
  }

  _values: WeightedColor[];

  constructor(values: FacetValueBase[] | string[] | WeightedColor[] = []) {
    this._values =
      values[0] instanceof WeightedColor
        ? _uniqBy(values as WeightedColor[], 'term')
        : typeof values[0] === 'string'
          ? _uniq(values as string[]).map((v) => new WeightedColor(v))
          : _uniqBy(values as FacetValueBase[], FacetValue.valueKey).map((v) => new WeightedColor(v.term));
  }

  // other

  toFacetValues(field: string): FacetValueBase[] {
    return this.map(({ term, color: value }) => ({ field, term, value }));
  }

  // array interface

  get(i: number): WeightedColor {
    return this._values[i];
  }

  set(i: number, value: WeightedColor): void {
    this._values[i] = value;
  }

  get length(): number {
    return this._values.length;
  }

  filter(func: ArrayPredicate<WeightedColor>, thisArg?: unknown): WeightedColorsArray {
    return new WeightedColorsArray(this._values.filter(func, thisArg).map((v) => v.term));
  }

  forEach(func: ArrayCallback<WeightedColor>, thisArg?: unknown): void {
    this._values.forEach(func, thisArg);
  }

  map<R>(func: ArrayCallback<WeightedColor, R>, thisArg?: unknown): R[] {
    return this._values.map(func, thisArg);
  }

  reduce(func: ArrayReducer<WeightedColor, WeightedColor>, init?: WeightedColor): WeightedColor;
  reduce<R>(func: ArrayReducer<WeightedColor, R>, init: R): R {
    return this._values.reduce<R>(func, init);
  }

  every(func: ArrayPredicate<WeightedColor>, thisArg: unknown): boolean {
    return this._values.every(func, thisArg);
  }
}

function addAndNormNotModified(colorValues: FacetValueBase[], value: FacetValueBase): FacetValueBase[] {
  const colors = new WeightedColorsArray(colorValues);
  const color = new WeightedColor(value.term);
  if (colors.length) {
    const notModifiedWeight = Math.round(100 / colors.length);
    const newWeight = Math.round(100 / (colors.length + 1));

    colors._values.filter((c) => c.weight === notModifiedWeight).forEach((c) => (c.weight = newWeight));
    color.weight = newWeight;
  }
  colors._values.push(color);
  return colors.toFacetValues(value.field);
}

function removeAndNormNotModified(colorValues: FacetValueBase[], value: FacetValueBase): FacetValueBase[] {
  const colors = new WeightedColorsArray(
    colorValues.filter((v) => FacetValue.valueKey(v) !== FacetValue.valueKey(value)),
  );
  if (colors.length) {
    const notModifiedWeight = Math.round(100 / (colors.length + 1));
    const newWeight = Math.round(100 / colors.length);

    colors._values.filter((c) => c.weight === notModifiedWeight).forEach((c) => (c.weight = newWeight));
    return colors.toFacetValues(value.field);
  }
  return [];
}
