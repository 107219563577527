import { useDispatch, useSelector } from 'react-redux';

import { setPageSize as setPageSizeAction } from 'Core/actions/request.js';
import { viewChangeRequested } from 'Core/actions/show.ts';
import Pagination from 'Components/pagination/pagination.jsx';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import { pageFromSelector, pageToSelector, totalHitsSelector } from 'Core/selectors/search.js';
import { viewSelector } from 'Core/selectors/show.ts';
import FacetDialogButton from './facetDialogButton.jsx';
import FacetToggle from './facetToggle.tsx';
import PageSizeSelect from './pageSizeSelect.jsx';
import SortSelect from './sortSelect.jsx';
import './style.scss';

const viewSetterCache = {};

export default function SearchHeader({
  template,
  facetToggleConfig,
  pageSizes,
  pageSizeSelectClass,
  doNotHideOnNoResults,
  sortEntries,
  sortSelectClass,
}) {
  const dispatch = useDispatch();

  const pageFrom = useSelector(pageFromSelector);
  const pageTo = useSelector(pageToSelector);
  const totalHits = useSelector(totalHitsSelector);
  const view = useSelector(viewSelector);
  const selectedVehicle = useSelector(selectedVehicleSelector);
  const pageSize = useSelector((state) => state.search.request.pageSize);

  if (totalHits <= 0 && !doNotHideOnNoResults) {
    return null;
  }

  const setPageSize = (size) => dispatch(setPageSizeAction(size));

  const pageSizeSelect = (
    <PageSizeSelect
      className={pageSizeSelectClass}
      pageSizes={pageSizes}
      pageSize={pageSize}
      setPageSize={setPageSize}
      key="page-size"
    />
  );

  const sortSelect = (
    <SortSelect
      className={[sortSelectClass, 'cm_sort-select'].filter(Boolean).join(' ')}
      sortEntries={sortEntries}
      key="sort"
    />
  );

  const setView = (view) =>
    viewSetterCache[view] || (viewSetterCache[view] = () => dispatch(viewChangeRequested(view)));

  const facetDialogButton = (templ) => <FacetDialogButton template={templ} key="facet-dialog-button" />;

  const pagination = (templ) => <Pagination template={templ} key="paging" />;

  const facetToggle = (templ) => {
    const props = {
      template: templ,
      key: 'facet-toggle',
      ...facetToggleConfig,
    };
    return <FacetToggle {...props} />;
  };

  return template.call({
    facetDialogButton,
    selectedVehicle: selectedVehicle.toString(),
    pageSizeSelect,
    facetToggle,
    pagination,
    pageFrom,
    pageTo,
    pageSize,
    pageSizes,
    sortSelect,
    setPageSize,
    setView,
    totalHits,
    view,
  });
}
