import _transform from 'lodash-es/transform.js';

import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { uriEncode, uriEncodeTerm } from './uriEncoding.js';

export default function getUriFromRequest(
  request,
  // should be fix via TypeScript, not via default values
  { pathname = '', goToSearchPage = false, stayOnPage = false } = {},
) {
  const url = new URL(
    pathname || getPathname(goToSearchPage || (!stayOnPage && !requestConfig.hasSearchResults)),
    uiConfig.searchPageBaseUrl || window.location.origin,
  );
  url.search = getSearch(request);
  url.hash = getHash(request);
  return url;
}

function getPathname(goToSearchPage) {
  return goToSearchPage ? uiConfig.searchPageUrl : window.location.pathname;
}

function getSearch(request) {
  if (uiConfig.useLocationHash) {
    return request.defaultSearch || '';
  }
  const facetsUri = makeFacetsUri(request);
  const result = [
    request.defaultSearch,
    request.defaultSearch && facetsUri && 'Facet=',
    facetsUri,
    makeParamsUri(request),
  ]
    .filter(Boolean)
    .join('&')
    .replace('Facet=&', 'Facet=');

  return uiConfig.platform === 'shift4shop' ? result.replace(/\*$/, '%2A') : result;
}

function getHash(request) {
  return uiConfig.useLocationHash
    ? [makeFacetsUri(request), makeParamsUri(request)].filter(Boolean).join('&')
    : '';
}

function makeFacetsUri({ selection }) {
  return selection
    .filter((sel) => !requestConfig.clientSideStoredFields.includes(sel.field))
    .map(({ field, term }) => `${uriEncode(field)}/${uriEncodeTerm(term)}`)
    .join('/');
}

const processors = {
  query: (q) => q && uriEncode(q),
  filterQuery: (q) => q && uriEncode(q),
  catalog: (c) => c && uriEncode(c),
  pageSize: (v) => v !== requestConfig.defaultPageSize && v,
  pageNumber: (v) => v && +v + 1,
  sort: (v) => v !== requestConfig.defaultSort && v,
};
function makeParamsUri(request) {
  return _transform(
    processors,
    (params, processor, name) => {
      const value = processor(request[name]);
      if (value) {
        params.push(`${name}=${value}`);
      }
    },
    [],
  ).join('&');
}
