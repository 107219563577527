import _uniq from 'lodash-es/uniq.js';
import _uniqBy from 'lodash-es/uniqBy.js';
import _pick from 'lodash-es/pick.js';

import { vehicleLandingFacetField } from 'Addons/fitmentSearch/vehicleLandingFacetField.ts';
import { createReducer, fromAction } from 'Core/reducers/common.ts';
import { prepareResponseFacets } from 'Core/reducers/search/response.js';
import { FacetValue, WeightedColorsArray } from 'Models/index.ts';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import { distribute } from 'Utils/array.js';

const initState = [];

export default createReducer(
  {
    DISCARD: (state, { value }) => discard(state, value),
    DISCARD_FIELD: (state, { field }) => discardField(state, field),
    DISCARD_MANY_FIELDS: (state, { fields }) => discardManyFields(state, fields),
    REPLACE_MANY_VALUES: (state, { values }) => replaceManyValues(state, values),
    REPLACE_COLOR: (state, { value }) => replaceColor(state, value),
    REPLACE_REQUEST: fromAction('selection', initState),
    REPLACE_VALUE: (state, { value }) => replaceManyValues(state, [value]),
    RESET_REQUEST: () => initState,
    TOGGLE: (state, { value, isSingleValue }) => toggle(state, { value, isSingleValue }),
    TOGGLE_COLOR: (state, { value }) => toggleColor(state, value),
    SET_RESPONSE: (_state, { payload: { Facets }, meta: { preselection } }) =>
      prepareResponseFacets(Facets, preselection).selection,
    VEHICLE_SELECTED: (state) => handleVehicleLandingField(state),
    VEHICLE_DISCARDING_REQUESTED: (state) => handleVehicleLandingField(state),
  },
  initState,
);

function handleVehicleLandingField(state) {
  if (state.some((f) => f.field === vehicleLandingFacetField)) {
    return discardField(state, vehicleLandingFacetField);
  }

  return state;
}

function discard(state, value) {
  return discardChildFields(
    removeFromSelection(state, FacetValue.valueKey, FacetValue.valueKey(value)),
    value.field,
  );
}

function discardField(state, field) {
  return discardChildFields(removeFromSelection(state, 'field', field), field);
}

function discardManyFields(state, fields) {
  return fields.reduce(discardField, state);
}

function replaceManyValues(state, values) {
  const fields = _uniq(values.map((v) => v.field));
  const addedValues = values.filter((v) => v.isSelected ?? true);

  return [...fields.reduce(discardField, state), ...addedValues];
}

function replaceColor(state, value) {
  return state.reduce((newState, sel) => {
    if (!FacetValue.equal(sel, value)) {
      return [...newState, sel];
    }
    return value.isSelected ?? true ? [...newState, value] : newState;
  }, []);
}

function toggle(state, { value, isSingleValue }) {
  const uniqueValue = value.isUnique && state.find((sel) => sel.isUnique && sel.field === value.field);
  if (FacetValue.isInCollection(value, state)) {
    return discard(state, value);
  }
  if (value.isSelected ?? true) {
    if (isSingleValue) {
      return replaceManyValues(state, [value]);
    }
    if (uniqueValue) {
      return [...discard(state, uniqueValue), value];
    }
    return [...state, value];
  }
  return state;
}

function toggleColor(state, value) {
  const wasSelected = FacetValue.isInCollection(value, state);
  if (wasSelected || (value.isSelected ?? true)) {
    const [colorSelection, otherSelection] = distribute(state, (sel) => sel.field === value.field);
    const newColorSelection = WeightedColorsArray.toggleAndNormNotModified(
      colorSelection,
      value,
      wasSelected,
    );
    return [...newColorSelection, ...otherSelection];
  }
  return state;
}

// helper functions

function discardChildFields(state, field) {
  return discardManyFields(state, facetsConfig.getChildrenForField(field).childFields);
}

const getByKey = (key) => (o) => (typeof key === 'function' ? key(o) : o[key]);
function removeFromSelection(state, key, value) {
  const get = getByKey(key);
  return state.filter((sel) => get(sel) !== value);
}

export function distinctSelection(selection) {
  return _uniqBy(selection, (facet) => Object.values(_pick(facet, ['field', 'term'])).join());
}
