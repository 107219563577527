//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-40:_4636,_6276,_4484,_1676,_6740,_8112,_4052,_1644;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-40')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-40', "_4636,_6276,_4484,_1676,_6740,_8112,_4052,_1644");
        }
      }catch (ex) {
        console.error(ex);
      }