import { useDispatch, useSelector } from 'react-redux';

import { isolatedDiscardVehicle } from 'Core/actions/fitmentSearch/index.js';
import { discardField } from 'Core/actions/request.js';
import {
  createResponseVehicleSelector,
  isVehicleSelectedSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { createFacetSelector } from 'Core/selectors/search.js';
import { verifyFitmentProductDataSelector } from 'Core/selectors/product.ts';
import { getUriFromRequest } from 'Modules/converter/index.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';

export default function VehicleLabel({
  template,
  name,
  appendedClasses,
  rootRef,
  isolatedKey,
  onChangeVehicle,
  openDialog,
  globalVehicleDiscardEnabled,
  templateName,
}) {
  const dispatch = useDispatch();

  const displayedVehicle = useSelector(createResponseVehicleSelector(isolatedKey));
  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const universalFacet = useSelector(createFacetSelector('Universal'));

  const verifyFitmentProductData = useSelector(verifyFitmentProductDataSelector);
  const fitsTheVehicle = verifyFitmentProductData?.fitsTheVehicle;

  const [nameRoot] = name.split('_');
  if (nameRoot === 'VerifyFitment' && !fitsTheVehicle) {
    return null;
  }

  const discardVehicle = () => {
    dispatch(discardField('Universal', { stayOnPage: true, sendRequest: false }));
    dispatch(isolatedDiscardVehicle(isolatedKey, globalVehicleDiscardEnabled));
    onChangeVehicle();
    fitmentSearchConfig.onVehicleDiscarded();
  };

  // VerifyFitment function
  const getVehicleUrl = (pathname) =>
    getUriFromRequest({ selection: displayedVehicle.selection }, { pathname, goToSearchPage: !pathname });

  const params = {
    fitmentSearchTitle: fitmentSearchConfig.fitmentSearchTitle,
    fits: fitsTheVehicle,
    getVehicleUrl,
    discardVehicle,
    changeVehicle: onChangeVehicle,
    openDialog,
    isVehicleSelected,
    vehicleString: displayedVehicle.toString(),
    vehicleValues: displayedVehicle.serializable,
    vehicle: displayedVehicle.filteredFieldMap,
    productData: verifyFitmentProductData,
    universalSelected: universalFacet?.selection.length === 1 && universalFacet.selection[0].value === 'True',
    template: templateName,
  };
  const component = template.call(params);

  return cloneSafe(component, rootRef, { appendedClasses });
}
