export default class WeightedColor {
  color: string;
  _weight: number;
  name: string;

  constructor(value: string) {
    [this.color, this.weight = 100, this.name = ''] = value.split(' ');
  }

  get weight(): number | string {
    return this._weight;
  }

  set weight(v: number | string) {
    this._weight = Math.round(+v);
  }

  get colorStyle(): string {
    return `#${this.color}`;
  }

  get term(): string {
    return `${this.color} ${this.weight}`;
  }

  clone({ weight = this.weight }: { weight?: number | string } = {}): WeightedColor {
    return new WeightedColor(`${this.color} ${weight} ${this.name}`);
  }

  equals(color: WeightedColor): boolean {
    return this.term === color.term;
  }
}
