export default {
  fields: {
    id: 'id',
    image: 'image',
    title: 'title',
    url: 'url',
  },
  getItemId() {
    const sa = window.ShopifyAnalytics;
    if (sa) {
      return `${sa.meta.product.id}`;
    }
    console.warn('window.ShopifyAnalytics not found');
    return '';
  },
  dropdownImageWidth: 100,
  dropdownImageHeight: 100,
  searchResultsImageWidth: 300,
  searchResultsImageHeight: 300,
};
