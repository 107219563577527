import requestConfig from 'Models/uiConfig/requestConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';

const knownParamNames = [
  'facet',
  'filterquery',
  'sort',
  'pagenumber',
  'page',
  'pagesize',
  'showed',
  'query',
  'catalog',
];
const ignoreNames = [
  ...knownParamNames,
  ...(requestConfig.getIgnoreDefaultSearchParamNames?.(uiConfig.pageType) || []),
];

export default location.search
  ? splitSearch(location.search)
      // without '=' check uri selection is treated as defaultSearch
      .filter((param) => param.includes('=') && !ignoreNames.includes(lowerParamName(param)))
      .join('&')
  : '';

function splitSearch(search: string) {
  return search.slice(1).split('&');
}

function lowerParamName(searchParam: string) {
  const [name] = searchParam.split('=');
  return name.toLowerCase();
}
