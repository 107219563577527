import { WeightedColorsArray } from 'Models/index.ts';
import { GET } from 'Modules/serverApi/common/httpClient.js';

export default async function extractColorsByUrl(imageurl: string): Promise<WeightedColorsArray> {
  return await GET('color/extract', { imageurl })
    .then((response) => {
      return new WeightedColorsArray(response);
    })
    .catch(async (err) => {
      throw `[Convermax]: Failed to extract colors: ${err?.message}`;
    });
}
