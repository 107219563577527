import { GET } from 'Modules/serverApi/common/httpClient.js';

import type { ServerModel } from 'Modules/serverApi/types';

export default async function item(key: string) {
  return await GET('item.json', { key })
    .then((response) => {
      return response as ServerModel.SearchItem;
    })
    .catch(async (err) => {
      throw `[Convermax]: Failed to get item by key ${key}: ${err?.message}`;
    });
}
